import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import VueCookie from 'vue-cookie'
import VueCryptojs from 'vue-cryptojs'
import orders from './modules/order'
import ccsheet from './modules/ccsheet'

Vue.use(VueCryptojs)
Vue.use(Router)

const decryptString = (cipherText) => {
  var bytes = Vue.CryptoJS.AES.decrypt(cipherText, '2020ModulesPermissionKey@TimerApp')
  return bytes.toString(Vue.CryptoJS.enc.Utf8)
}

let permissionsAndRoles = VueCookie.get(process.env.VUE_APP_PERMISSIONS_AND_ROLES)
let permissions = null
let role = null
if (permissionsAndRoles) {
  permissionsAndRoles = JSON.parse(decryptString(permissionsAndRoles))
  permissions = permissionsAndRoles.permissions
  role = permissionsAndRoles.role
}

const checkListPermission = (moduleName) => {
  var permissionsAndRole = VueCookie.get(process.env.VUE_APP_PERMISSIONS_AND_ROLES)
  permissions = JSON.parse(decryptString(permissionsAndRole)).permissions
  let hasPermission = true
  if (permissions && role) {
    hasPermission = (['admin', 'superadmin'].includes(role.slug) || (permissions[moduleName] && permissions[moduleName].list ? permissions[moduleName].list : false))
  }
  return hasPermission
}

/* features obj localstorage get */
let features = window.localStorage.getItem(process.env.VUE_APP_FEATURES_LIST) || ''
if (features) features = JSON.parse(decryptString(features)) || {}
// get feature obj from cookie & handle this
const allTiles = [{
  name: 'projects',
  routes: [{
    path: '/projects',
    component: () => import('@/views/Projects/Index.vue'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      name: 'projects',
      meta: { moduleName: 'projects' },
      component: () => import('@/views/Projects/List.vue')
    }, {
      path: ':id',
      name: 'project_action',
      meta: { moduleName: 'projects' },
      component: () => import('@/views/Projects/Actions.vue')
    }, {
      path: ':project_id/custom_modules/:module_id',
      component: () => import('@/views/Projects/CustomModules/Index.vue'),
      children: [{
        path: '/',
        name: 'project_custom_modules',
        meta: { moduleName: 'custommodule' },
        component: () => import('@/views/Projects/CustomModules/NewList.vue'),
        // component: () => import('@/views/Projects/CustomModules/List.vue'),
        props: true
      }, {
        path: 'action/:record_id?',
        meta: { moduleName: 'custommodule' },
        name: 'project_custom_module_action',
        component: () => import('@/views/Projects/CustomModules/Actions.vue')
      }]
    }, {
      path: ':project_id/module_report',
      name: 'project_module_report',
      component: () => import('@/views//ModuleReport/Index.vue')
    }, {
      path: ':project_id/drawing',
      component: () => import('@/views/Projects/Drawings/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectdrawing' },
      children: [{
        path: '/',
        name: 'drawing',
        meta: { moduleName: 'projectdrawing' },
        component: () => import('@/views/Projects/Drawings/NewList.vue')
        // component: () => import('@/views/Projects/Drawings/List.vue')
      }, {
        path: 'action/:id?',
        name: 'drawing_actions',
        meta: { moduleName: 'projectdrawing' },
        component: () => import('@/views/Projects/Drawings/Actions.vue')
      }]
    }, {
      path: ':project_id/ndt',
      component: () => import('@/views/Projects/NDT/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectndt' },
      children: [{
        path: '/',
        name: 'ndt',
        meta: { moduleName: 'projectndt' },
        component: () => import('@/views/Projects/NDT/NewList.vue')
        // component: () => import('@/views/Projects/NDT/List.vue')
      }, {
        path: 'action/:id?',
        name: 'ndt_actions',
        meta: { moduleName: 'projectndt' },
        component: () => import('@/views/Projects/NDT/Actions.vue')
      }]
    }, {
      path: ':project_id/pressure-test-report',
      component: () => import('@/views/Projects/PressureTestReport/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectpressuretest' },
      children: [{
        path: '/',
        name: 'pressure-test-report',
        meta: { moduleName: 'projectpressuretest' },
        component: () => import('@/views/Projects/PressureTestReport/NewList.vue')
        // component: () => import('@/views/Projects/PressureTestReport/List.vue')
      }, {
        path: 'action/:id?',
        name: 'pressure-test-report_actions',
        meta: { moduleName: 'projectpressuretest' },
        component: () => import('@/views/Projects/PressureTestReport/Actions.vue')
      }]
    }, {
      path: ':project_id/weld-log',
      component: () => import('@/views/Projects/WeldLog/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectweldlog' },
      children: [{
        path: '/',
        name: 'weld-log',
        meta: { moduleName: 'projectweldlog' },
        component: () => import('@/views/Projects/WeldLog/NewList.vue')
        // component: () => import('@/views/Projects/WeldLog/List.vue')
      }, {
        path: 'action/:id?',
        name: 'weld-log_actions',
        meta: { moduleName: 'projectweldlog' },
        component: () => import('@/views/Projects/WeldLog/Actions.vue')
      }]
    }, {
      path: ':project_id/surface-report',
      component: () => import('@/views/Projects/SurfaceReport/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectsurfacereport' },
      children: [{
        path: '/',
        name: 'surface-report',
        meta: { moduleName: 'projectsurfacereport' },
        component: () => import('@/views/Projects/SurfaceReport/NewList.vue')
        // component: () => import('@/views/Projects/SurfaceReport/List.vue')
      }, {
        path: 'action/:id?',
        name: 'surface-report_actions',
        meta: { moduleName: 'projectsurfacereport' },
        component: () => import('@/views/Projects/SurfaceReport/Actions.vue')
      }]
    }, {
      path: ':project_id/technical-report',
      component: () => import('@/views/Projects/TechnicalReport/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projecttechnicalreport' },
      children: [{
        path: '/',
        name: 'technical-report',
        meta: { moduleName: 'projecttechnicalreport' },
        component: () => import('@/views/Projects/TechnicalReport/NewList.vue')
        // component: () => import('@/views/Projects/TechnicalReport/List.vue')
      }, {
        path: 'action/:id?',
        name: 'technical-report_actions',
        meta: { moduleName: 'projecttechnicalreport' },
        component: () => import('@/views/Projects/TechnicalReport/Actions.vue')
      }]
    }, {
      path: ':project_id/material-list',
      component: () => import('@/views/Projects/MatrialList/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectmateriallist' },
      children: [{
        path: '/',
        name: 'material-list',
        meta: { moduleName: 'projectmateriallist' },
        component: () => import('@/views/Projects/MatrialList/NewList.vue')
        // component: () => import('@/views/Projects/MatrialList/List.vue')
      }, {
        path: 'action/:id?',
        name: 'material-list_actions',
        meta: { moduleName: 'projectmateriallist' },
        component: () => import('@/views/Projects/MatrialList/Actions.vue')
      }]
    }, {
      path: ':project_id/pwht',
      component: () => import('@/views/Projects/PWHT/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectpwht' },
      children: [{
        path: '/',
        name: 'pwht',
        meta: { moduleName: 'projectpwht' },
        component: () => import('@/views/Projects/PWHT/NewList.vue')
        // component: () => import('@/views/Projects/PWHT/List.vue')
      }, {
        path: 'action/:id?',
        name: 'pwht_actions',
        meta: { moduleName: 'projectpwht' },
        component: () => import('@/views/Projects/PWHT/Actions.vue')
      }]
    }, {
      path: ':project_id/pwps',
      component: () => import('@/views/Projects/PWPS/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectpwps' },
      children: [{
        path: '/',
        name: 'pwps',
        meta: { moduleName: 'projectpwps' },
        component: () => import('@/views/Projects/PWPS/NewList.vue')
        // component: () => import('@/views/Projects/PWPS/List.vue')
      }, {
        path: 'action/:id?',
        name: 'pwps_actions',
        meta: { moduleName: 'projectpwps' },
        component: () => import('@/views/Projects/PWPS/Actions.vue')
      }]
    }, {
      path: ':project_id/wps',
      component: () => import('@/views/Projects/WPS/Index.vue'),
      meta: { requiresAuth: true, moduleName: 'projectwps' },
      children: [{
        path: '/',
        name: 'wps',
        meta: { moduleName: 'projectwps' },
        component: () => import('@/views/Projects/WPS/NewList.vue')
        // component: () => import('@/views/Projects/WPS/List.vue')
      }, {
        path: 'action/:id?',
        name: 'wps_actions',
        meta: { moduleName: 'projectwps' },
        component: () => import('@/views/Projects/WPS/Actions.vue')
      }]
    }]
  }]
}, {
  name: 'files',
  routes: [{
    path: '/files',
    component: () => import('@/views/Files/Index.vue'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      name: 'files',
      component: () => import('@/views/Files/Files.vue')
    }]
  }]
}, {
  name: 'deviations',
  routes: [{
    path: '/deviations',
    component: () => import('@/views/Deviations/Index.vue'),
    meta: { requiresAuth: true, moduleName: 'deviation' },
    children: [{
      path: '/',
      name: 'deviations',
      meta: { moduleName: 'deviation' },
      // component: () => import('@/views/Deviations/List.vue')
      component: () => import('@/views/Deviations/NewList.vue')
    }, {
      path: 'add',
      name: 'deviation_add',
      meta: { moduleName: 'deviation' },
      component: () => import('@/views/Deviations/Actions.vue')
    }, {
      path: ':id',
      name: 'deviation_edit',
      meta: { moduleName: 'deviation' },
      component: () => import('@/views/Deviations/Actions.vue')
    }]
  }]
}, {
  name: 'calendar',
  routes: [{
    path: '/calendar',
    meta: { requiresAuth: true },
    component: () => import('@/views/Calendar/Index.vue'),
    children: [{
      path: '/',
      name: 'calendar',
      component: () => import('@/views/Calendar/Calendar.vue')
    }, {
      path: 'actions/:hour_id?',
      name: 'calendar_actions',
      component: () => import('@/views/Calendar/Action.vue')
    }]
  }]
}, {
  name: 'stemp',
  routes: [{
    path: '/stemp',
    name: 'stemp',
    meta: { requiresAuth: true },
    component: require('@/views/Timer/Stemp.vue').default
  }]
}, {
  name: 'checklists',
  routes: [{
    path: '/global_checklist',
    component: () => import('@/views/GlobalChecklist/Index.vue'),
    meta: { requiresAuth: true, moduleName: 'checklists' },
    children: [{
      path: '/',
      name: 'global_checklist',
      meta: { moduleName: 'checklists' },
      component: () => import('@/views/GlobalChecklist/List.vue')
    }]
  }]
}, {
  name: 'chemicallist',
  routes: [{
    path: '/chemicallist',
    component: () => import('@/views/Chemicallist/Index.vue'),
    meta: { requiresAuth: true, moduleName: 'chemicallist' },
    children: [
      {
        path: '/',
        name: 'chemicallist',
        meta: { moduleName: 'chemicallist' },
        component: () => import('@/views/Chemicallist/NewList.vue')
      },
      // {
      //   path: 'add',
      //   name: 'chemicallist_add',
      //   meta: { moduleName: 'chemicallist' },
      //   component: () => import('@/views/Chemicallist/Actions.vue')
      // },
      {
        path: ':chemicallist_id/checklists',
        name: 'chemicallist_checklist',
        meta: { moduleName: 'chemicallist' },
        component: () => import('@/views/Chemicallist/Checklist/List.vue')
      },
      {
        path: ':id',
        name: 'chemicallist_edit',
        meta: { moduleName: 'chemicallist' },
        component: () => import('@/views/Chemicallist/Actions.vue')
      }
    ]
  }]
}, {
  name: 'equipments',
  routes: [{
    path: '/equipments',
    component: () => import('@/views/Equipments/Index.vue'),
    meta: { requiresAuth: true, moduleName: 'equipment' },
    children: [{
      path: '/',
      name: 'equipments',
      meta: { moduleName: 'equipment' },
      component: () => import('@/views/Equipments/NewListEquipement.vue')
      // component: () => import('@/views/Equipments/List.vue')
    }, {
      path: 'add',
      name: 'equipment_add',
      meta: { moduleName: 'equipment' },
      component: () => import('@/views/Equipments/Actions.vue')
    }, {
      path: ':id',
      name: 'equipment_edit',
      meta: { moduleName: 'equipment' },
      component: () => import('@/views/Equipments/Actions.vue')
    }]
  }]
}, {
  name: 'warehouse',
  routes: [{
    path: '/warehouse',
    component: () => import('@/views/Warehouse/dashboard/Index'),
    meta: { requiresAuth: true }
  },
  ...orders,
  ...ccsheet
  ]
}, {
  name: 'materials',
  parentPath: '/projects',
  routes: [{
    path: ':project_id/materials',
    component: () => import('@/views/Warehouse/orders/customer/View'),
    meta: { requiresAuth: true },
    children: [{
      path: '/',
      component: () => import('@/views/Warehouse/orders/customer/Item')
    }, {
      path: 'pick/:product_code?',
      name: 'project_pick_material',
      component: () => import('@/views/Warehouse/orders/customer/Pick')
    }]
  }]
}, {
  name: 'project_checklist',
  parentPath: '/projects',
  routes: [{
    path: ':project_id/checklists',
    name: 'project_checklist',
    component: () => import('@/views/Projects/Checklist/List.vue')
  }]
}, {
  name: 'project_equipment',
  parentPath: '/projects',
  routes: [{
    path: ':project_id/equipments',
    name: 'project_equipment',
    meta: { moduleName: 'projectequipment' },
    component: () => import('@/views/Equipments/NewListEquipement.vue')
  }]
}, {
  name: 'project_deviation',
  parentPath: '/projects',
  routes: [{
    path: ':project_id/deviations',
    name: 'project_deviation',
    meta: { moduleName: 'projectdeviation' },
    // component: () => import('@/views/Deviations/List.vue')
    component: () => import('@/views/Deviations/NewList.vue')
  }]
}, {
  name: 'equipment_checklist',
  parentPath: '/equipments',
  routes: [{
    path: ':equipment_id/checklists',
    name: 'equipment_checklist',
    component: () => import('@/views/Equipments/Checklist/List.vue')
  }]
}, {
  name: 'tasks',
  routes: [{
    path: '/tasks',
    meta: { requiresAuth: true },
    component: () => import('@/views/Tasks/Index.vue'),
    children: [{
      path: '/',
      name: 'Tasks',
      component: () => import('@/views/Tasks/List.vue')
    }]
  }]
}, {
  name: 'checklist_files',
  routes: [{
    path: '/checklist_files',
    component: () => import('@/views/ChecklistFiles/ChecklistFiles.vue'),
    meta: { requiresAuth: true }
  }]
}]

var routes = [{
  path: '*',
  redirect: '/dashboard'
}, {
  path: '/login',
  name: 'login',
  component: () => import('@/views/Auth/Login.vue'),
  beforeEnter (from, to, next) {
    if (!VueCookie.get(process.env.VUE_APP_TOKEN)) {
      next()
    } else {
      next('/dashboard')
    }
  }
}, {
  path: '/directlogin/:otp/:domain/:lang/:user_id',
  name: 'directlogin',
  component: () => import('@/views/Auth/DirectLogin.vue')
}, {
  path: '/dashboard',
  component: () => import('@/views/Dashboard/Index.vue'),
  meta: { requiresAuth: true },
  children: [{
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/List.vue')
  }]
}, {
  path: '/myshifts',
  name: 'Shifts',
  meta: { requiresAuth: true },
  component: () => import('@/views/MyShifts/MyShift.vue')
},
{
  path: '/irscanner',
  component: () => import('@/views/Irscanner/Index.vue'),
  meta: { requiresAuth: true },
  children: [{
    path: '/',
    name: 'irscanner',
    component: () => import('@/views/Irscanner/irscanner.vue')
  }, {
    path: ':id',
    name: 'irscanner_edit',
    meta: { moduleName: 'irscanner' },
    component: () => import('../views/Irscanner/Actions.vue')
  }]
}, {
  path: '/news',
  name: 'News',
  meta: { requiresAuth: true },
  component: () => import('@/views/News/Index.vue')
}, {
  path: '/not_found',
  name: 'Not Found',
  component: () => import('@/components/NotFound.vue')
}]

function setRouteInParentPath (tileObj) {
  const parentRoute = routes.find(x => x.path === tileObj.parentPath)
  if (parentRoute && parentRoute.children) {
    parentRoute.children = [...parentRoute.children, ...tileObj.routes]
  }
}

/* add routes based on the features disabled */

const setInChildrenOfParent = {
  warehouse: 'materials',
  equipments: ['project_equipment', 'equipment_checklist'],
  // checklists: ['project_checklist', 'equipment_checklist'],
  projects: 'project_checklist',
  deviations: 'project_deviation'
}
const checklistFileObj = {
  enabled: 1,
  id: 9,
  label: 'Checklist Files',
  link: null,
  slug: 'checklists'
}
features = { ...features, ...{ checklist_files: checklistFileObj } }
Object.keys(features).forEach(key => {
  if (Number(features[key].enabled)) {
    const tileObj = allTiles.find(x => x.name === key)
    if (tileObj) routes = [...routes, ...tileObj.routes]
    if (Object.keys(setInChildrenOfParent).includes(key)) {
      const values = key === 'equipments' ? setInChildrenOfParent[key] : [setInChildrenOfParent[key]]
      values.forEach(childrenOfParent => {
        const tileObj = allTiles.find(x => x.name === childrenOfParent)
        if (tileObj && tileObj.parentPath) setRouteInParentPath(tileObj)
      })
    }
  }
})
const router = new Router({
  mode: 'history',
  routes
})
const permissionModules = [
  'custommodule',
  'equipment',
  'chemicallist',
  'deviation',
  'checklists',
  'projects',
  'projectdeviation',
  'projectequipment',
  'projectdrawing',
  'projectndt',
  'projectpressuretest',
  'projectweldlog',
  'projectsurfacereport',
  'projecttechnicalreport',
  'projectmateriallist',
  'projectpwht',
  'projectpwps',
  'projectwps'
]

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // CHECK FOR TOKEN AVALIABLE
    const activeRoute = to.matched.find(record => record.name === to.name)
    if (!VueCookie.get(process.env.VUE_APP_TOKEN)) {
      store.commit('resetAuth', false)
      store.commit('resetPermissions', false)
      VueCookie.delete(process.env.VUE_APP_TOKEN)
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else if (activeRoute && activeRoute.meta && activeRoute.meta.moduleName && permissionModules.includes(activeRoute.meta.moduleName)) {
      if (checkListPermission(activeRoute.meta.moduleName)) next()
      else next({ path: '/' })
    } else next()
  } else {
    next()
  }
})

export default router
