export const sv = {
  message: {
    layout: {
      login: 'Inloggning',
      ortext: 'ELLER',
      logout: 'Logga ut',
      rfidsignin: 'Logga in med RFID',
      SignInpassword: 'Logga in med lösenord',
      ScanRFID: 'Skanna din RFID-tagg',
      dashboard: 'Dashboard',
      timer: 'Timer',
      projects: 'Projekt',
      stemp: 'Stemp',
      calendar: 'Kalender',
      deviation: 'Avvikelse',
      home: 'Hem',
      deliver: 'Leverera',
      more: 'Mer',
      files: 'Filer',
      equipments: 'Utrustning',
      checklists: 'Checklistor',
      drawing: 'Ritning',
      pressureTestReport: 'Tryckprovsrapport',
      weldlog: 'Loggbok för svetsning',
      event: 'händelse',
      ndt: 'NDT',
      surfaceReport: 'Ytrapport',
      technicalReport: 'Teknisk rapport',
      matrialList: 'Förteckning över material',
      pwht: 'PWHT',
      pwps: 'PWPS',
      wps: 'WPS',
      warehouse: 'Lager',
      tasks: 'Uppgifter',
      orders: 'Beställningar',
      facilities: 'Anläggning',
      chemicallist: 'Kemisk lista',
      asbuilt: 'Som byggt',
      autoLogout: 'automatisk utloggning'
    },
    login: {
      language: 'Språk',
      back: 'Tillbaka',
      userName: 'Användarnamn',
      password: 'Lösenord',
      authFailed: 'Autentisering misslyckades!',
      host: 'Värd'
    },
    common: {
      pwaNotEnabled: 'PWA är inte aktiverat, kontakta administratören',
      delete_row: 'Ta bort rad',
      install: 'Installera den här applikationen på din hemskärm för snabb och enkel åtkomst när du är på språng.',
      justTab: 'Tryck bara på',
      addToHome: 'och sedan på "Lägg till på hemskärmen".',
      english: 'Engelska',
      norwegian: 'Norska',
      lithuanian: 'Lithuanian', // need
      polish: 'Polish', // need
      swedish: 'Swedish', // need
      save: 'Spara',
      saveAndClose: 'Spara och stänga',
      send: 'Skicka',
      edit: 'Redigera',
      cancel: 'Avbryt',
      update: 'Uppdatera',
      delete: 'Ta bort',
      updateAndClose: 'Uppdatera och stänga',
      close: 'Stäng',
      new: 'Ny',
      isActive: 'Är aktiv',
      addNew: 'Lägg till nytt',
      savedSuccess: 'Sparad framgångsrikt!',
      addedSucess: 'Tillagd med framgång!',
      updatedSuccess: 'Uppdaterad med framgång !',
      deleteSuccess: 'Borttagen med framgång !',
      backToList: 'Tillbaka till listan',
      search: 'Sök på',
      yes: 'Ja',
      no: 'Nej',
      isDefault: 'Är standard',
      loadingMsg: 'Laddar, vänligen vänta',
      deleteConfirm: 'Radera Bekräfta',
      areYouSure: 'Är du säker på att du vill radera?',
      validationIssue: 'Kontrollera de obligatoriska fälten!',
      total: 'Totalt',
      noResults: 'Inga resultat hittades !',
      others: 'Andra',
      typeHere: 'Skriv här...',
      position: 'Position',
      color: 'Färg',
      maxCharacters: 'Max {charCount} tecken',
      changedPassword: 'Lösenordet har ändrats med framgång',
      import: 'Importera',
      note: 'Anteckning',
      invalidEmail: 'Felaktigt e-postmeddelande',
      no_records: 'Inga poster hittades!',
      no_data: 'Inga uppgifter finns tillgängliga',
      ok: 'Ok',
      date: 'Datum',
      comments: 'Kommentarer',
      draftMsg: 'Sparad som utkast',
      createProjectRole: 'Skapa en projektroll',
      updateProjectRole: 'Uppdatera projektrollen',
      module: 'Modul',
      selectTemplate: 'Välj mall',
      documentTemplate: 'Dokumentmall',
      download: 'Ladda ner',
      uploadedSuccess: 'Uppladdning lyckad!',
      weekTotalHours: 'Veckans timmar',
      addWorktype: 'Lägg till arbetstyp',
      updateWorktype: 'Uppdatera arbetstyp',
      worktype: 'Arbetstyp',
      newUpdate: 'Ny uppdatering tillgänglig!',
      refresh: 'Uppdatera',
      moduleName: 'Modulens namn',
      updatedOn: 'Uppdaterad den',
      columns: 'Kolumner',
      confirmCancel: 'Bekräfta Avbryt',
      areYouSureCancel: 'Är du säker på att du vill avbryta?',
      not_approved_notification: 'Hej! {count} tidsregistrering(ar) i vecka {weeknumber} är inte godkänd.',
      hourExceedNotification: 'Angiven timme överstiger 24 timmar',
      options: 'Alternativ',
      label: 'Etikett',
      value: 'Värde',
      confirmStatusChange: 'Bekräfta Statusändring',
      areYouSureToChangeStatus: 'Är du säker på att du vill ändra status till {statusText}?',
      complete: 'komplett',
      incomplete: 'Ofullständig',
      name: 'Namn',
      number: 'Nummer',
      noPermission: 'Tyvärr! Du har inte rätt att {permissionType} {modul}',
      charsLimit35: 'Ange högst {längd} tecken.',
      confirmAddHour: 'Bekräfta att du vill lägga till överskridande timmar',
      sureToAddExceedingHour: 'Den angivna timmen överskrider arbetstiden. Är du säker på att du vill lägga till?',
      warning: 'Varning',
      required: 'Krävs',
      timerFeatureDisabled: 'Timer-funktionen är inaktiverad',
      locationNotSet: 'Platsen är inte inställd.',
      type: 'Typ',
      changeVideoSource: 'Ändra videokälla',
      permissionDenied: 'Kameratillstånd nekas!, aktivera det i webbläsarens inställningar!',
      time: 'Time',
      orderno: 'Order No',
      ready_for_production: 'Klar for produksjon',
      production: 'Produksjon',
      on_hold: 'On hold',
      finished: 'Ferdig',
      of: 'av',
      ndt_percentage_error: 'NDT-procenten måste ha 0 till 100',
      lengthError: 'Längden måste vara mindre än 10',
      update_properties: 'Uppdatera egenskaper',
      add_properties: 'Lägg till egenskaper',
      preparing_fields: 'Förbereder fält...',
      from_attributs: 'FORMATRIBUT',
      enter_title: 'Ange titel på varje kolumn:',
      loading_grid: 'Laddar rutnät...',
      from_properties: 'FORM EGENSKAPER',
      dragging: 'Dra...',
      catch_up: 'Allt ikapp!!!',
      add_header: 'Lägg till rubrik',
      add_footer: 'Lägg till sidfot',
      drop_fields: 'Släpp fälten här',
      for_header: 'FÖR HUVUD',
      for_footer: 'FÖR Footer',
      add_html: 'LÄGG TILL HTML-INNEHÅLL',
      relate: 'Relatera',
      unrelate: 'Osläkt',
      values: 'Värderingar',
      scan: 'Skanna',
      filename: 'Filnamn',
      reset: 'Återställa',
      doYouWantContinue: 'Vill du fortfarande fortsätta?',
      stempHint: 'Om du väljer Shift kan du starta stämpeln',
      hoursExeed: 'överstiger 24 timmar',
      on: 'På',
      reachedHours: 'Nådde 24 timmar',
      invaliHours: 'Ogiltiga timmar',
      notValidNumber: 'Ej giltigt nummer',
      invalidName: 'Ogiltigt namn',
      filterSaveResult: 'Sökning gör att du kan se filtret du har tillämpat och det filtrerade resultatet kan endast ses i rapporten om du sparar checklistan',
      seemsInvalid: 'Timmar verkar inte vara giltiga!',
      shiftContinue: 'Din stemptimme fortsätter...',
      no_fields_found: 'Inga checklistfält hittades',
      sharePoint: 'Sharepoint kan ha felaktiga referenser eller konfigurationer saknas!.',
      dragAndDropTips: 'Att spara är obligatoriskt vid varje dra och släpp för att undvika felplacering av rader',
      afterCopyFilter: 'Filteracion kommer att utföras tills du sparar checklistan',
      archived: 'Arkiverat',
      datagrid_filter_help_text_1: 'Resultaten kommer att filtreras på samma sätt som förra gången filteralternativ.',
      datagrid_filter_help_text_2: 'När en ny rad läggs till kommer filter att tas bort och alla rader läggs till.',
      datagrid_filter_help_text_3: 'Filtren är skiftlägesokänsliga, så vi kan använda både versaler och gemener.',
      datagrid_filter_help_text_4: 'Vi kan inte filtrera samma kolumn två gånger.',
      datagrid_filter_help_text_5: 'Dra och släpp kommer att ändras av filterresultat, om användaren sparar checklistan.',
      datagrid_filter_help_text_6: 'Textområdet som filtreras av ordet mellan någon del av meningen.',
      datagrid_filter_help_text_7: 'Det är obligatoriskt att fylla i båda fälten och dess värde för att utföra filteråtgärder.',
      sharepointCharacters: "Det här fältet ska inte innehålla några av dessa tecken '*:<>?/\\|.'",
      filenotfound: 'Filen hittades inte'
    },
    timer: {
      stop: 'Stoppa',
      ga_til_time: 'Gå till timlistan',
      minutes: 'minuter',
      start: 'Start',
      place: 'Plats',
      hours: 'Timmar',
      activity: 'Aktivitet',
      pause: 'Paus',
      from: 'Från',
      till: 'Till',
      shift: 'Gruva vakter',
      startTime: 'Starttid',
      endTime: 'Sluttid',
      editHours: 'Redigera timme',
      approved: 'Godkänd',
      notApproved: 'Inte godkänd',
      delivered: 'Levererat',
      notDelivered: 'Inte levererat',
      year: 'År',
      week: 'Vecka',
      nonBillable: 'Semester / Frånvaro',
      hourlogging: 'Timloggning',
      noHours: 'Inga timmar hittades för leverera !',
      deliveredMsg: 'Levererat framgångsrikt',
      addHours: 'Lägg till timme',
      deliverHours: 'Leverera timmar',
      hoursExceeding: 'Timer har överskridit 8 timmar',
      cancelTimer: 'Avbryt timer',
      uploadSuccess: 'Uppladdning lyckad',
      picture: 'Ta en bild',
      imageNeeded: 'Ladda upp en bild innan du stoppar timern!',
      nonbillableworktype: 'Semester / frånvarotyp'
    },
    projects: {
      startDate: 'Startdatum',
      endDate: 'Slutdatum',
      all: 'Alla',
      allActive: 'Alla Aktiva',
      myAll: 'Mina alla',
      myActive: 'Mina aktiva',
      project: 'Projekt',
      customer: 'Kund',
      status: 'Status',
      noActiveProjects: 'Inga aktiva projekt!',
      selectProject: 'Välj projekt',
      projectMaterialDisabled: 'Material som är inaktiverat för det här projektet',
      drawingGroup: 'Ritgrupp'
    },
    calendar: {
      today: 'Idag',
      month: 'Månad',
      day: 'Dag',
      weekNotExists: 'Veckan finns inte',
      yearValid: 'Måste vara fyra siffror',
      valid: 'Måste vara giltigt',
      yearTooLessValidation: 'Året måste vara större än 1920',
      weekLock: 'De valda datumen ligger i den låsta veckan',
      dayLocked: 'Det valda datumet ligger i den låsta veckan',
      otherHourEntries: 'Andra timangivelser'
    },
    shifts: {
      shift: 'Mine vakter', // need
      documents: 'Dokument:',
      not_found: 'Ingen beskrivning och webbadress hittades.',
      notValidPausedTime: 'Paustiden är längre än skifttiden',
      continue: 'Fortsätta',
      clear: 'Klar'
    },
    deviation: {
      deviations: 'Avvikelse',
      register_deviation: 'Registrera avvikelse',
      view_deviation: 'View Deviation',
      high: 'Hög',
      medium: 'Medelhög',
      low: 'Låg',
      closed: 'Stängt',
      registered: 'Registrerad',
      process: 'Bearbeta',
      actions: 'Åtgärder',
      title: 'Material',
      reported_by: 'Rapporterat av',
      location: 'Loc',
      reason: 'Orsak',
      document: 'Dokument',
      category: 'Kategori',
      estimate_cost: 'Kostnadsberäkning',
      severity: 'Svårighetsgrad',
      deviation_status: 'Avvikelse Status',
      deadline: 'Tidsfrist',
      description: 'Beskrivning',
      responsible: 'Ansvarig',
      copyto: 'Kopia till',
      upload_files: 'Ladda upp',
      required_message: 'Detta fält är obligatoriskt',
      consequences: 'Konsekvens',
      changeRequest: 'Ändringsbegäran',
      hse: 'HSE',
      deviation_number: 'Deviation Number',
      underlying_reason: 'Underlying Reason',
      corrective_actions: 'Corrective Actions',
      effect_of_measures: 'Effect Of Measures'
    },
    checklist: {
      title: 'Checklista', // need
      updateChecklist: 'Uppdatera checklistan',
      pickCheckList: 'Välj en checklista',
      addCheckList: 'Lägg till checklista',
      hmsKs: 'HMS/KS',
      templateName: 'Mallnamn',
      checklist_recipients: 'Email Mottagare',
      checklist_recipient_hint: 'Ange e-post med komma(,) separerad.',
      projectnr: 'PO Ranked',
      projectname: 'Projektnamn',
      aonr: 'AO Ranked',
      workordername: 'Arbetsordernamn',
      customer: 'Kund',
      customercontact: 'Kundkontakt',
      projectleader: 'Projektledare',
      importCSV: 'Importer CSV',
      CSVFileInfo: 'Her kan du importere data fra en csv-fil til en sjekkliste.',
      removeRow: 'Fjern 1. rad hvis den har overskrifter.',
      sameOrederColumn: 'Sørg for at kolonnene er i samme rekkefølge som du har definert i sjekklisten.',
      semicolonDelimiter: 'Bruk semikolon som csv-skilletegn.',
      withoutImport: 'Uten import',
      addCSVFile: 'Legg til CSV-fil',
      report_header: 'Report header',
      copy_confirm: 'Do you want to copy the checklist?',
      filter: 'Filtrera',
      apply: 'Tillämpa'
    },
    equipments: {
      equipment: 'Utrustning',
      addEquipment: 'Lägg till utrustning',
      updateEquipment: 'Uppdatera utrustning',
      viewEquipment: 'View Equipment',
      active: 'Aktiv',
      quarantine: 'Karantän',
      discarded: 'Kasserad',
      warrantydate: 'Datum för garanti',
      dateofPurchase: 'Inköpsdatum',
      vendor: 'Leverantör',
      modelNumber: 'Modellnummer',
      product: 'Produkt',
      serialNumber: 'Serienummer',
      capacity: 'Kapacitet',
      groups: 'Grupper',
      plannedCalibration: 'Planerad kalibrering',
      scrapDate: 'Datum för skrotning',
      equipmentNumber: 'Utrustningens nummer',
      placement: 'Placering',
      notifyByEmail: 'Meddela via e-post',
      mustAllocateToProjectOrLocation: 'Utrustningen måste tilldelas antingen projekt eller plats.',
      confirm: 'Är du säker?',
      company: 'Företag'
    },
    customModule: {
      modules: 'Moduler',
      customModule: 'Anpassad modul',
      customModules: 'Anpassade moduler',
      uploadDocument: 'Ladda upp dokument',
      isCompleted: 'Är slutförd',
      add: 'Lägg till',
      urlNotFound: 'Nedladdningsadress inte hittad',
      completed: 'Slutförd',
      notCompleted: 'Inte slutförd',
      fileSizeLimitMsg: 'Gränsen för filstorlek har överskridits för en eller flera filer (Max.Limit {maxSize}MB).'
    },
    moduleReports: {
      title: 'Module reports', // need
      chartyAxis: 'Antal poster (per modul)'
    },
    projectFeature: {
      drawingNumber: 'Ritning nr.',
      poRanked: 'PO Rankad',
      operator: 'Operatör',
      reportNumber: 'Rapport nr.',
      uploadNdtDocument: 'Ladda upp NDT-dokument',
      aoRanked: 'AO Rankad',
      NDTSpecifications: 'NDT-specifikationer',
      NDTScope: 'NDT-omfattning',
      filterMaterials: 'Filtermaterial',
      chargedLotNumber: 'Charde/Lot nr.',
      selectFiles: 'Välj filer',
      createdOn: 'Skapad den',
      weldingID: 'Svetsning ID',
      weldNumber: 'Svetsnummer',
      wpsNumber: 'WPS nr.',
      wpsAsComma: 'Ange WPS nr. Som kommaseparerad.',
      weldEnd: 'Slut på svetsning',
      vtSignDate: 'VT Sign./Datum',
      rtSignDate: 'RT Sign./Datum',
      utSignDate: 'UT Sign./Date',
      mtPtSignDate: 'MT/PT Sign./Date',
      createdDate: 'Skapat datum',
      tegNr: 'Teg nr.',
      posNr: 'Pos nr.',
      batchNr: 'Parti nr.',
      heatNr: 'Värme nr.',
      onStock: 'I lager',
      out: 'Utgående',
      recordNumber: 'Registreringsnummer',
      chartSpeed: 'Hastighet i diagrammet',
      chartNumber: 'Diagramnummer',
      unrestrictedTo: 'Obegränsad till',
      restrictedRateOfRise: 'Begränsad Stigningshastighet',
      holdTemperatureRequired: 'Hålltemperatur som krävs',
      holdTemperatureActual: 'Hålltemperatur faktisk temperatur',
      holdTimeActual: 'Hålltid Faktisk hålltid',
      holdTimeRequired: 'Hålltid som krävs',
      restrictedRateDown: 'Begränsad hastighet nedåt',
      unrestrictedRateOfFall: 'Obegränsad fallhastighet',
      weldDate: 'Svetsdatum',
      performedBy: 'Utförd av',
      approvedBy: 'Godkänd av',
      approvedDate: 'Godkänt datum',
      weldLogDate: 'Svetsdatum',
      productNumber: 'Produktnummer',
      weldLength: 'Svetslängd (mm)',
      designation_1: 'Del 1 Beteckning',
      designation_2: 'Del 2 Beteckning',
      thickness_1: 'Tjocklek 1(mm)',
      thickness_2: 'Tjocklek 2(mm)',
      weld_diameter: 'Svetsdiameter (mm)',
      notAccepted: 'Inte accepterad',
      ndtStatus: 'Ndt-status',
      isDiameter: 'Är diameter',
      ndt: '%NDT',
      weldingCompound: 'Svetsmassa',
      batch_lot: 'Batc/lot',
      choose: 'Välja',
      pipe_description: 'Rörbeskrivning'
    },
    whsDashboard: {
      scanText: 'Skanna ordernummer eller streckkod',
      customerOrder: 'Kundbeställningar',
      receiveSupplierOrder: 'Ta emot leverantörsbeställningar',
      adjustmentOrders: 'Justeringsbeställningar',
      transferOrders: 'Överföringsorder',
      cycleCount: 'Räkna cykler',
      product: 'Product',
      supplierOrder: 'Leverantörsorder',
      returnOrder: 'Returorder'
    },
    ccsheet: {
      warehouse: 'Warehouse', // need
      created: 'Skapade',
      counted: 'Räknade',
      count: 'Räkning',
      reCount: 'Omräkning',
      closeComplete: 'Stäng och slutför',
      unit: 'Enhet',
      stock: 'Lager',
      scanLocation: 'Skanna eller ange plats',
      nextLoc: 'Nästa plats',
      info: 'Meddelande!',
      continueCount: 'Fortsätt räkna',
      clearAndRecount: 'Rensa och räkna om',
      completeRecount: 'Slutföra omräkning',
      ccsheetComplete: 'CCSheet kommer att fyllas i. Fortsätta?',
      noSheetFound: 'Inget CCSheet hittades!',
      noProductOnLocation: 'Ingen produkt finns på denna plats!',
      stockTake: 'Inventering för den valda platsen har redan utförts. Vill du fortsätta räkna på denna plats eller rensa data och utföra en ny inventering?',
      ccsheetAddProduct: 'Produkten har inte lagts till i detta ccsheet, vill du lägga till?'
    },
    product: {
      supplierartno: 'Supplier article number',
      supplier: 'Supplier Name',
      barcode: 'Barcode',
      noProductFound: 'Ingen produkt har hittats!',
      minQty: 'Min. antal',
      orderQty: 'Beställd kvantitet',
      qty: 'Antal',
      batch: 'Parti',
      scanLoc: 'Select Loc',
      noLocFound: 'Ingen plats hittad!'
    },
    order: {
      products: 'Produkter',
      avail: 'Tillgänglig',
      pickProducts: 'Velg produkter',
      ordered: 'Ord.',
      received: 'Rec.',
      pickedQty: 'Pic.',
      pickOrder: 'Plocka beställning',
      delivery: 'Del',
      rest: 'Vila',
      complete: 'Komplett',
      pick: 'Välj',
      scanProduct: 'Skanna streckkoden för en produkt',
      scanBatch: 'Skanna batchnummer',
      picked: 'Plockat',
      toPick: 'För att plocka',
      location: 'Loc', // need
      pickLocation: 'Välj plats',
      qtyExceeds: 'Antal överstiger',
      noOrderFound: 'Ingen order hittad!',
      notValidBatch: 'Batchnumber är inte giltigt!',
      receiveOrder: 'Ta emot beställning',
      receive: 'Ta emot',
      batchExists: 'Batchnumret finns redan!',
      receiveWarning: 'Vill du lägga till {qty} st av {produkt} i lagret?',
      completedSuccess: 'Avslutad med framgång!',
      addFreight: 'Vill du lägga till frakt?',
      cost: 'Kostnad',
      price: 'Pris',
      orderedQtyHigh: 'Den mottagna kvantiteten är högre än den beställda, vill du fortsätta?',
      collect: 'Samla in',
      receiving: 'Mottagande',
      availableToPick: 'Tillgänglig(a) kvantitet(er) att ta emot',
      adjust: 'Justera',
      orderComplete: 'Beställningen kommer att slutföras. Fortsätt?',
      orderDate: 'Orderdatum'
    },
    tasks: {
      forYour: 'För din',
      information: 'information',
      acknowledge: 'Bekräfta',
      review: 'Granska',
      approval: 'Godkännande',
      subject: 'Ämne',
      dueDate: 'Förfallodag',
      approve: 'Godkänna',
      disapprove: 'Avslås',
      keep: 'Behåll',
      remove: 'Ta bort'
    },
    mixed: { // all mixed needs translation
      scanSerialNumber: 'Scan serial number',
      noEquipmentFound: 'No equipment found',
      sharedUrl: 'Delad URL',
      checklistfiles: 'Checklista filer',
      attachments: 'Bilagor',
      signature: 'Signatur',
      nochecklists: 'Inga checklistor',
      checklistName: 'Checklista namn',
      noOfDocuments: 'Nej av dokument',
      documentTitle: 'Dokument titel',
      irscanner: 'irscanner',
      qrCode: 'QR Code',
      qrQty: 'QR Quantity',
      scanQRCode: 'Skanna QR kod',
      attachmentsBelow: 'Hitta bilagor nedan',
      noChemicalFound: 'Ingen kemikalielista hittades',
      barCodeNotDecoded: 'Misslyckades med att dekoda streckkoden från QR-koden.'
    },
    chemicallist: {
      chemical: 'Kemisk',
      provider: 'Leverantör',
      repository: 'Förvar',
      last_revised: 'Senast reviderad',
      viewChemical: 'Kemisk',
      chemicalName: 'Kemiskt namn'
    },
    news: {
      news: 'Nyheter',
      next: 'Nästa',
      previous: 'Föregående'
    }
  }
}
