export const pl = {
  message: {
    layout: {
      login: 'Zaloguj się',
      logout: 'Wyloguj się',
      rfidsignin: 'Zaloguj się za pomocą RFID',
      SignInpassword: 'Zaloguj się hasłem',
      ScanRFID: 'Zeskanuj swój znacznik RFID',
      ortext: 'LUB',
      // dashboard: 'Tablica rozdzielcza',
      dashboard: 'panel',
      timer: 'Timer',
      projects: 'Projekty',
      stemp: 'Zestaw stempla',
      calendar: 'Kalendarz',
      deviation: 'Rozkład',
      home: 'Strona główna',
      deliver: 'Dostarcz',
      more: 'Więcej',
      files: 'Dokumenty',
      equipments: 'Wyposażenie',
      checklists: 'Listy kontrolne',
      drawing: 'Rysunek',
      pressureTestReport: 'Raport z próby ciśnieniowej',
      weldlog: 'Dziennik spawania',
      event: 'wydarzenie',
      ndt: 'NDT',
      surfaceReport: 'Raport o powierzchni',
      technicalReport: 'Raport techniczny',
      matrialList: 'Lista matryc',
      pwht: 'PWHT',
      pwps: 'PWPS',
      wps: 'WPS',
      warehouse: 'Magazyn',
      tasks: 'Zadania',
      orders: 'Zamówienia',
      facilities: 'Obiekt',
      chemicallist: 'Rejestr substancji',
      nbselftL: '',
      asbuilt: 'Zbudowany jako',
      autoLogout: 'automatyczne wylogowanie'
    },
    login: {
      language: 'Język',
      back: 'Powrót',
      userName: 'Nazwa użytkownika',
      password: 'Hasło',
      authFailed: 'Autentykacja nie powiodła się !',
      host: 'Host'
    },
    common: {
      pwaNotEnabled: 'PWA nie jest włączone, skontaktuj się z administratorem',
      delete_row: 'Usuń wiersz',
      install: 'Zainstaluj tę aplikację na swoim ekranie głównym, aby mieć do niej szybki i łatwy dostęp, gdy jesteś w ruchu.',
      justTab: 'Po prostu dotknij',
      addToHome: 'następnie "Dodaj do ekranu głównego".',
      english: 'Angielski',
      norwegian: 'Norweski',
      lithuanian: 'Lithuanian', // need
      polish: 'Polish', // need
      swedish: 'Swedish', // need
      save: 'Zapisz',
      saveAndClose: 'Zapisz i zamknij',
      send: 'Wyślij',
      edit: 'Edytuj',
      cancel: 'Anuluj',
      update: 'Aktualizacja',
      delete: 'Usuń',
      updateAndClose: 'Zaktualizuj i zamknij',
      close: 'Zamknij',
      new: 'Nowa strona',
      isActive: 'Jest aktywny',
      addNew: 'Dodaj nowy',
      savedSuccess: 'Zapisano pomyślnie!',
      addedSucess: 'Dodane pomyślnie !',
      updatedSuccess: 'Zaktualizowano pomyślnie !',
      deleteSuccess: 'Usunięto pomyślnie !',
      backToList: 'Powrót do listy',
      search: 'Szukaj',
      yes: 'Tak',
      no: 'Nie',
      isDefault: 'Jest domyślna',
      loadingMsg: 'Ładowanie, proszę czekać',
      deleteConfirm: 'Usuń Potwierdź',
      areYouSure: 'Czy na pewno chcesz usunąć?',
      validationIssue: 'Proszę sprawdzić wymagane pola!',
      total: 'Całkowity',
      noResults: 'Nie znaleziono żadnych wyników !',
      others: 'Inne',
      typeHere: 'Wpisz tutaj...',
      position: 'Pozycja',
      color: 'Kolor',
      maxCharacters: 'Max {charCount} znaków',
      changedPassword: 'Hasło zostało zmienione pomyślnie',
      import: 'Import',
      note: 'Notatka',
      invalidEmail: 'Nieprawidłowy e-mail',
      no_records: 'Nie znaleziono żadnych rekordów!',
      no_data: 'Brak dostępnych danych',
      ok: 'Ok',
      date: 'Data',
      comments: 'Komentarze',
      draftMsg: 'Zapisane jako wersja robocza',
      createProjectRole: 'Utwórz rolę projektu',
      updateProjectRole: 'Aktualizuj rolę projektową',
      module: 'Moduł',
      selectTemplate: 'Wybierz szablon',
      documentTemplate: 'Szablon dokumentu',
      download: 'Pobierz',
      uploadedSuccess: 'Załadowano pomyślnie!',
      weekTotalHours: 'Tydzień godz.',
      addWorktype: 'Dodaj typ pracy',
      updateWorktype: 'Aktualizuj typ pracy',
      worktype: 'Typ pracy',
      newUpdate: 'Nowa aktualizacja dostępna!',
      refresh: 'Odśwież',
      moduleName: 'Nazwa modułu',
      updatedOn: 'Zaktualizowano na',
      columns: 'Kolumny',
      confirmCancel: 'Potwierdź anulowanie',
      areYouSureCancel: 'Czy na pewno chcesz anulować?',
      not_approved_notification: 'Cześć! {count} rejestracja(y) czasu w tygodniu {weeknumber} nie jest zatwierdzona.',
      hourExceedNotification: 'Zarejestrowane godziny przekraczają 24 godziny.',
      options: 'Opcje',
      label: 'Etykieta',
      value: 'Wartość',
      confirmStatusChange: 'Potwierdź zmianę statusu',
      areYouSureToChangeStatus: 'Czy na pewno chcesz zmienić status na {statusText} ?',
      complete: 'kompletny',
      incomplete: 'niekompletny',
      name: 'Nazwa',
      number: 'Numer',
      noPermission: 'Przepraszamy! Nie masz uprawnień do {permissionType} {module}.',
      charsLimit35: 'Proszę wpisać nie więcej niż {długość} znaków',
      confirmAddHour: 'Potwierdź dodanie przekroczenia godzin',
      sureToAddExceedingHour: 'Wprowadzona godzina przekracza godziny pracy, czy na pewno chcesz dodać ?',
      warning: 'Ostrzeżenie',
      required: 'Wymagane',
      timerFeatureDisabled: 'Funkcja timera wyłączona',
      locationNotSet: 'Nie ustawiono lokalizacji',
      type: 'Rodzaj',
      changeVideoSource: 'Zmień źródło wideo',
      permissionDenied: 'Zezwolenie na kamerę jest zablokowane!, proszę włączyć je w ustawieniach przeglądarki!',
      time: 'Time',
      orderno: 'Order No',
      ready_for_production: 'Klar for produksjon',
      production: 'Produksjon',
      on_hold: 'On hold',
      finished: 'Ferdig',
      of: 'z',
      ndt_percentage_error: 'Procent NDT musi wynosić od 0 do 100',
      lengthError: 'Długość musi być mniejsza niż 10',
      update_properties: 'Zaktualizuj właściwości',
      add_properties: 'Dodaj właściwości',
      preparing_fields: 'Przygotowanie pól...',
      from_attributs: 'ATRYBUTY FORMULARZA',
      enter_title: 'Wprowadź tytuł każdej kolumny:',
      loading_grid: 'Wczytywanie siatek...',
      from_properties: 'WŁAŚCIWOŚCI FORMULARZA',
      dragging: 'Włóka...',
      catch_up: 'Wszystko zajęte!!!',
      add_header: 'Dodaj nagłówek',
      add_footer: 'Dodaj stopkę',
      drop_fields: 'Upuść pola tutaj',
      for_header: 'DLA NAGŁÓWKA',
      for_footer: 'DLA STOPKI',
      add_html: 'DODAJ TREŚĆ HTML',
      relate: 'Odnosić się',
      unrelate: 'Niezwiązane',
      values: 'Wartości',
      scan: 'Skanowanie',
      filename: 'Nazwa pliku',
      reset: 'Resetowanie',
      doYouWantContinue: 'Nadal chcesz kontynuować?',
      stempHint: 'Jeśli wybierzesz Shift, możesz rozpocząć stempel',
      hoursExeed: 'przekraczający 24 godziny',
      on: 'Na',
      reachedHours: 'Osiągnięto 24 godziny',
      invaliHours: 'Nieprawidłowe godziny',
      notValidNumber: 'Nieprawidłowy numer',
      invalidName: 'Błędna nazwa',
      filterSaveResult: 'Wyszukiwanie pozwala zobaczyć zastosowany filtr, a przefiltrowany wynik można zobaczyć w raporcie tylko po zapisaniu listy kontrolnej',
      seemsInvalid: 'Wydaje się, że godziny są nieprawidłowe!',
      shiftContinue: 'Twoja godzina stempla trwa...',
      no_fields_found: 'Nie znaleziono pól listy kontrolnej',
      sharePoint: 'Sharepoint może mieć nieprawidłowe poświadczenia lub brakuje konfiguracji!',
      dragAndDropTips: 'Zapisywanie jest obowiązkowe przy każdym przeciąganiu i upuszczaniu, aby uniknąć błędnego rozmieszczenia wierszy',
      afterCopyFilter: 'Akcja filtrowania będzie wykonywana do momentu zapisania listy kontrolnej',
      archived: 'Zarchiwizowane',
      datagrid_filter_help_text_1: 'Wyniki będą filtrowane tak samo, jak opcje filtrowania ostatniego razu.',
      datagrid_filter_help_text_2: 'Po dodaniu nowego wiersza filtry zostaną usunięte, a wszystkie wiersze zostaną dodane.',
      datagrid_filter_help_text_3: 'Filtry nie rozróżniają wielkości liter, więc możemy używać zarówno wielkich, jak i małych liter.',
      datagrid_filter_help_text_4: 'Nie możemy dwukrotnie filtrować tej samej kolumny.',
      datagrid_filter_help_text_5: 'Przeciągnij i upuść zostanie zmodyfikowany według wyniku filtra, jeśli użytkownik zapisze listę kontrolną.',
      datagrid_filter_help_text_6: 'Obszar tekstowy filtrowany według słowa pomiędzy dowolną częścią zdania.',
      datagrid_filter_help_text_7: 'Obowiązkowe jest wypełnienie zarówno pola, jak i jego wartości, aby wykonać akcję filtrowania.',
      sharepointCharacters: "To pole nie powinno zawierać żadnego z tych znaków '*:<>?/\\|.'",
      filenotfound: 'Plik nie znaleziony'
    },
    timer: {
      stop: 'Zatrzymaj',
      ga_til_time: 'Przejdź do listy godzin',
      minutes: 'minut',
      start: 'Start',
      place: 'Miejsce',
      hours: 'Godziny',
      activity: 'Działanie',
      pause: 'Pauza',
      from: 'Od',
      till: 'Do',
      shift: 'Mój vakter',
      startTime: 'Czas rozpoczęcia',
      endTime: 'Czas końcowy',
      editHours: 'Edytuj Godzinę',
      approved: 'Zatwierdzono',
      notApproved: 'Nie zatwierdzony',
      delivered: 'Dostarczono',
      notDelivered: 'Nie dostarczono',
      year: 'Rok',
      week: 'Tydzień',
      nonBillable: 'Urlop / Nieobecność',
      hourlogging: 'Hourlogging',
      noHours: 'Nie znaleziono godzin dla dostawy !',
      deliveredMsg: 'Dostarczono pomyślnie',
      addHours: 'Dodaj godzinę',
      deliverHours: 'Dostarczono godziny',
      hoursExceeding: 'Timer przekroczył 8 godzin',
      cancelTimer: 'Anuluj Timer',
      uploadSuccess: 'Prześlij pomyślnie',
      picture: 'Zrób zdjęcie',
      imageNeeded: 'Proszę załadować zdjęcie przed zatrzymaniem timera!',
      nonbillableworktype: 'Rodzaj urlopu / nieobecności'
    },
    projects: {
      startDate: 'Data rozpoczęcia',
      endDate: 'Data końcowa',
      all: 'Wszystkie',
      allActive: 'Wszystkie Aktywne',
      myAll: 'Moje wszystkie',
      myActive: 'Mój Aktywny',
      project: 'Projekt',
      customer: 'Klient',
      status: 'Status',
      noActiveProjects: 'Brak aktywnych projektów!',
      selectProject: 'Wybierz projekt',
      projectMaterialDisabled: 'Materiały wyłączone dla tego projektu',
      drawingGroup: 'Grupa rysunkowa'
    },
    calendar: {
      today: 'Dzisiaj',
      month: 'Miesiąc',
      day: 'Dzień',
      weekNotExists: 'Tydzień nie istnieje',
      yearValid: 'Musi składać się z czterech cyfr',
      valid: 'Musi być ważny',
      yearTooLessValidation: 'Rok musi być większy niż 1920',
      weekLock: 'Wybrane daty znajdują się w zablokowanym tygodniu',
      dayLocked: 'Wybrana data znajduje się w zablokowanym tygodniu',
      otherHourEntries: 'Inne wpisy godzinowe'
    },
    shifts: {
      shift: 'Mine vakter', // need
      documents: 'Dokumenty:',
      not_found: 'Nie znaleziono opisu i adresu url.',
      notValidPausedTime: 'Czas pauzy jest dłuższy niż czas zmiany',
      continue: 'Kontynuować',
      clear: 'Jasne'
    },
    deviation: {
      deviations: 'Odchylenie',
      register_deviation: 'Odchylenie rejestru',
      view_deviation: 'View Deviation',
      high: 'Wysokie',
      medium: 'Średnie',
      low: 'Niskie',
      closed: 'Zamknięta',
      registered: 'Zarejestrowane',
      process: 'Proces',
      actions: 'Działania',
      title: 'Materiały',
      reported_by: 'Zgłoszone przez',
      location: 'Loc',
      reason: 'Powód',
      document: 'Dokument',
      category: 'Kategoria',
      estimate_cost: 'Szacunkowy koszt',
      severity: 'Waga',
      deviation_status: 'Status odchylenia',
      deadline: 'Termin realizacji',
      description: 'Opis',
      responsible: 'Odpowiedzialny',
      copyto: 'Kopia Do',
      upload_files: 'Prześlij',
      required_message: 'To pole jest wymagane',
      consequences: 'Konsekwencje',
      changeRequest: 'Żądanie zmiany',
      hse: 'HSE',
      deviation_number: 'Deviation Number',
      underlying_reason: 'Underlying Reason',
      corrective_actions: 'Corrective Actions',
      effect_of_measures: 'Effect Of Measures'
    },
    checklist: {
      title: 'Lista kontrolna', // need
      updateChecklist: 'Aktualizacja listy kontrolnej',
      pickCheckList: 'Wybierz listę kontrolną',
      addCheckList: 'Dodaj listę kontrolną',
      hmsKs: 'HMS/KS',
      templateName: 'Nazwa szablonu',
      checklist_recipients: 'Odbiorca emaila',
      checklist_recipient_hint: 'Wpisz adres e-mail oddzielony przecinkami (,).',
      projectnr: 'PO Ranked',
      projectname: 'Nazwa Projektu',
      aonr: 'AO Ranked',
      workordername: 'Nazwa zlecenia pracy',
      customer: 'Klient',
      customercontact: 'Kontakt z klientem',
      projectleader: 'Lider Projektu',
      importCSV: 'Importer CSV',
      CSVFileInfo: 'Her kan du importere data fra en csv-fil til en sjekkliste.',
      removeRow: 'Fjern 1. rad hvis den har overskrifter.',
      sameOrederColumn: 'Sørg for at kolonnene er i samme rekkefølge som du har definert i sjekklisten.',
      semicolonDelimiter: 'Bruk semikolon som csv-skilletegn.',
      withoutImport: 'Uten import',
      addCSVFile: 'Legg til CSV-fil',
      report_header: 'Report header',
      copy_confirm: 'Do you want to copy the checklist?',
      filter: 'Filtr',
      apply: 'Stosować'
    },
    equipments: {
      equipment: 'Wyposażenie',
      addEquipment: 'Dodaj wyposażenie',
      updateEquipment: 'Zaktualizuj wyposażenie',
      viewEquipment: 'View Equipment',
      active: 'Aktywny',
      quarantine: 'Kwarantanna',
      discarded: 'Odrzucony',
      warrantydate: 'Data gwarancji',
      dateofPurchase: 'Data zakupu',
      vendor: 'Sprzedawca',
      modelNumber: 'Numer modelu',
      product: 'Produkt',
      serialNumber: 'Numer seryjny',
      capacity: 'Pojemność',
      groups: 'Grupy',
      plannedCalibration: 'Planowana kalibracja',
      scrapDate: 'Data złomowania',
      equipmentNumber: 'Numer urządzenia',
      placement: 'Umieszczenie',
      notifyByEmail: 'Powiadomienie przez e-mail',
      mustAllocateToProjectOrLocation: 'Musi przypisać sprzęt do projektu lub lokalizacji.',
      confirm: 'Jesteś pewny?',
      company: 'Firma'
    },
    customModule: {
      modules: 'Moduły',
      customModule: 'Moduł niestandardowy',
      customModules: 'Moduły niestandardowe',
      uploadDocument: 'Prześlij dokument',
      isCompleted: 'Jest zakończony',
      add: 'Dodaj',
      urlNotFound: 'Nie znaleziono adresu url do pobrania',
      completed: 'Ukończono',
      notCompleted: 'Nie ukończono',
      fileSizeLimitMsg: 'Przekroczono limit rozmiaru pliku dla jednego lub więcej plików (Max.Limit {maxSize}MB).'
    },
    moduleReports: {
      title: 'Module reports', // need
      chartyAxis: 'Liczba rekordów (na moduł)'
    },
    projectFeature: {
      drawingNumber: 'Nr rysunku',
      poRanked: 'PO Ranga',
      operator: 'Operator',
      reportNumber: 'Nr raportu',
      uploadNdtDocument: 'Załaduj dokument NDT',
      aoRanked: 'Ranking AO',
      NDTSpecifications: 'Specyfikacja badań nieniszczących',
      NDTScope: 'Zakres badań nieniszczących',
      filterMaterials: 'Materiały filtracyjne',
      chargedLotNumber: 'Nr kartki/działki',
      selectFiles: 'Wybierz pliki',
      createdOn: 'Utworzono dnia',
      weldingID: 'ID spawania',
      weldNumber: 'Numer spoiny',
      wpsNumber: 'Nr WPS.',
      wpsAsComma: 'Wprowadź numer WPS oddzielony przecinkami.',
      weldEnd: 'Koniec spoiny',
      vtSignDate: 'VT Znak./Data',
      rtSignDate: 'RT Znak./Data',
      utSignDate: 'UT Znak./Data',
      mtPtSignDate: 'MT/PT Znak./Data',
      createdDate: 'Data utworzenia',
      tegNr: 'Teg nr.',
      posNr: 'Nr poz.',
      batchNr: 'Nr partii',
      heatNr: 'Nr kat.',
      onStock: 'Na stanie',
      out: 'Out',
      recordNumber: 'Numer zapisu',
      chartSpeed: 'Prędkość wykresu',
      chartNumber: 'Numer wykresu',
      unrestrictedTo: 'Bez ograniczeń Do',
      restrictedRateOfRise: 'Ograniczona Szybkość narastania',
      holdTemperatureRequired: 'Wymagana temperatura podtrzymania',
      holdTemperatureActual: 'Rzeczywista temperatura podtrzymania',
      holdTimeActual: 'Rzeczywisty czas podtrzymania',
      holdTimeRequired: 'Wymagany czas podtrzymania',
      restrictedRateDown: 'Ograniczona szybkość opadania',
      unrestrictedRateOfFall: 'Nieograniczona prędkość opadania',
      weldDate: 'Data spawania',
      performedBy: 'Wykonane przez',
      approvedBy: 'Zatwierdzony przez',
      approvedDate: 'Zatwierdzona data',
      weldLogDate: 'Data spawania',
      productNumber: 'Numer produktu',
      weldLength: 'Długość spawania (mm)',
      designation_1: 'Część 1 Oznaczenie',
      designation_2: 'Część 2 Oznaczenie',
      thickness_1: 'Grubość 1 (mm)',
      thickness_2: 'Grubość 2 (mm)',
      weld_diameter: 'Średnica spawania (mm)',
      notAccepted: 'Nie zaakceptowany',
      ndtStatus: 'Stan ndt',
      isDiameter: 'Czy średnica?',
      ndt: '%NDT',
      weldingCompound: 'Związek spawalniczy',
      batch_lot: 'Batc/lot',
      choose: 'Wybierać',
      pipe_description: 'Opis rury'
    },
    whsDashboard: {
      scanText: 'Zeskanuj numer zamówienia lub kod kreskowy',
      customerOrder: 'Zamówienia klientów',
      receiveSupplierOrder: 'Otrzymane zamówienia od dostawców',
      adjustmentOrders: 'Zamówienia korekty',
      transferOrders: 'Polecenia przelewu',
      cycleCount: 'Liczenie cykli',
      product: 'Product',
      supplierOrder: 'Zamówienie dostawcy',
      returnOrder: 'Zamówienie zwrotu'
    },
    ccsheet: {
      warehouse: 'Warehouse', // need
      created: 'Utworzono',
      counted: 'Policzone',
      count: 'Liczenie',
      reCount: 'Przeliczenie',
      closeComplete: 'Zamknij i zakończ',
      unit: 'Jednostka',
      stock: 'Zapasy',
      scanLocation: 'Zeskanuj lub wprowadź lokalizację',
      nextLoc: 'Następna lokalizacja',
      info: 'Wiadomość!',
      continueCount: 'Kontynuuj liczenie',
      clearAndRecount: 'Wyczyść i policz ponownie',
      completeRecount: 'Zakończ ponowne liczenie',
      ccsheetComplete: 'CCSheet zostanie ukończony. Kontynuować?',
      noSheetFound: 'Nie znaleziono arkusza CCSheet!',
      noProductOnLocation: 'Nie znaleziono żadnego produktu w tej lokalizacji!',
      stockTake: 'Przeprowadzono już inwentaryzację dla wybranej lokalizacji. Czy chcesz kontynuować liczenie w tej lokalizacji, czy wyczyścić dane i przeprowadzić nową inwentaryzację?',
      ccsheetAddProduct: 'Product is not added to this ccsheet would like to add ?'
    },
    product: {
      supplierartno: 'Supplier article number',
      supplier: 'Supplier Name',
      barcode: 'Barcode',
      noProductFound: 'Nie znaleziono produktu!',
      minQty: 'Min. ilość',
      orderQty: 'Zamówienie Ilość',
      qty: 'Qty',
      batch: 'Batch',
      scanLoc: 'Select Loc',
      noLocFound: 'Nie znaleziono lokalizacji!'
    },
    order: {
      products: 'Produkty',
      avail: 'Dostępne',
      pickProducts: 'Velg produkter',
      ordered: 'Ord.',
      received: 'Rec.',
      pickedQty: 'Pic.',
      pickOrder: 'Zamówienie',
      delivery: 'Del',
      rest: 'Odpoczynek',
      complete: 'Zakończ',
      pick: 'Pick',
      scanProduct: 'Zeskanuj kod kreskowy produktu',
      scanBatch: 'Zeskanuj numer partii',
      picked: 'Wybrano',
      toPick: 'Do pobrania',
      location: 'Loc', // need
      pickLocation: 'Wybierz lokalizację',
      qtyExceeds: 'Ilość przekracza',
      noOrderFound: 'Nie znaleziono zamówienia!',
      notValidBatch: 'Numer partii jest nieprawidłowy!',
      receiveOrder: 'Przyjmij zamówienie',
      receive: 'Odbierz',
      batchExists: 'Numer partii już istnieje!',
      receiveWarning: 'Czy chcesz dodać {qty} sztuk {produktu} do magazynu?',
      completedSuccess: 'Zakończono pomyślnie!',
      addFreight: 'Czy chcesz dodać fracht?',
      cost: 'Koszt',
      price: 'Cena',
      orderedQtyHigh: 'Otrzymana ilość jest wyższa niż zamówiona, czy chcesz kontynuować?',
      collect: 'Odbierz',
      receiving: 'Odbiór',
      availableToPick: 'Dostępna ilość do odbioru',
      adjust: 'Dostosuj',
      orderComplete: 'Zamówienie zostanie zrealizowane. Kontynuować?',
      orderDate: 'Data zamówienia'
    },
    tasks: {
      forYour: 'Dla Ciebie',
      information: 'Informacje',
      acknowledge: 'Potwierdź',
      review: 'Przeglądaj',
      approval: 'Zatwierdzenie',
      subject: 'Temat',
      dueDate: 'Termin',
      approve: 'Zatwierdzić',
      disapprove: 'Nie zatwierdzić',
      keep: 'Zachowaj',
      remove: 'Usuń'
    },
    mixed: {
      scanSerialNumber: 'Scan serial number',
      noEquipmentFound: 'No equipment found',
      checklistfiles: 'Pliki listy kontrolnej',
      sharedUrl: 'Udostępniony adres URL',
      attachments: 'Załączniki',
      signature: 'Podpis',
      nochecklists: 'Brak list kontrolnych',
      checklistName: 'Lista kontrolna Nazwa',
      noOfDocuments: 'Liczba dokumentów',
      documentTitle: 'Tytuł dokumentu',
      qrCode: 'Kod QR',
      irscanner: 'irscanner',
      qrQty: 'Ilość QR',
      scanQRCode: 'Skanuj kod QR',
      attachmentsBelow: 'Znajdź załączniki poniżej',
      noChemicalFound: 'Nie znaleziono listy substancji chemicznych',
      barCodeNotDecoded: 'Nie udało się odszyfrować kodu kreskowego z kodu QR.'
    },
    chemicallist: {
      chemical: 'Chemiczny',
      provider: 'Dostawca',
      repository: 'Magazyn',
      last_revised: 'Ostatnio przeglądane',
      viewChemical: 'Chemiczny',
      chemicalName: 'Nazwa chemiczna'
    },
    news: {
      news: 'Wiadomości',
      next: 'Następ',
      previous: 'Poprzedni'
    }
  }
}
